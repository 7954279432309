@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
 
body{font-family: 'Poppins', sans-serif; font-size: 14px;background-color: #fff;    }
li{list-style: none;}
a{text-decoration: none; color: #00baf2;}
.btn{display: inline-flex;align-items: center;justify-content: center;position: relative;box-sizing: border-box; outline: 0px; cursor: pointer;-webkit-user-select: none; user-select: none;vertical-align: middle;appearance: none;text-decoration: none; font-weight: 500; font-size: 13px;line-height: 1.75;letter-spacing: 0.02857em;text-transform: capitalize;min-width: 64px;padding: 6px 16px;border-radius: 4px;transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;color: rgb(255, 255, 255);box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;border: 1px solid transparent;margin: 0 2px;gap: 5px;font-size: 14px;}
.btn-primary {color: #fff;background-color: #484fbf;border-color: #484fbf;}
.btn-primary:hover {background-color: transparent;border-color: #484f68;color: #484f68;}
.btn-blue{background-color: #00baf2;color: #fff; }
.btn-blue:hover{background-color: #08a7d7;color: #fff; border-color: #00baf2;}
.btn-blue-border{background-color: transparent; border-color: #3f9bdf; color: #00baf2;}
.btn-blue-border:hover{background-color: #00baf2;color: #fff; }
.btn-secondry {background-color: #e8e7fd;   color: #2915ff;border-color: #2915ff;}
.btn-secondry:hover{    background-color: transparent;border-color: #484f68;color: #484f68;}
.btn-white{background-color: #fff; color: #000;}
.btn-white:hover{background-color: #fff; color: #000;}
.btn-success {background-color: #1ca94c;   color: #fff;border-color: #1ca94c;}
.btn-success:hover{ background-color: #1ca94c;   color: #fff;border-color: #1ca94c;}
h1{ font-size: 52px; font-weight: 800;}
h2{font-size: 30px; color: #0f172b;}
h3{font-size: 22px;  color: #0f172b;} 
h4{font-size: 18px;  color: #0f172b;}
h5 {font-size: 16px;}
p {
  
  font-size: 16px;
}
.form-control:focus{box-shadow: none; border-color: #3a8cf0;   }
.form-group {margin-bottom: 20px;}
.form-group label{    display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  color: #484f68;}
.select2-container {width: 100%;}
.select2-container {border: 1px solid #f4f4f4;border-radius: 0.25rem;height: 50px;background: #f4f4f4;}
.select2-container .select2-choice .select2-arrow {right: 10px;top: 16px;}
.select2-container .select2-choice{color: #6c757d;}
.badge{font-size: 13px; padding: 7px 10px ; font-weight: 500; border-radius: 3px;}
 .bg-inverse-success {background-color:rgba(15, 183, 107,0.12) !important;color: #26af48 !important;}
 .bg-inverse-warning {background-color: rgba(255, 152, 0,0.12) !important;color: #f39c12 !important;}
 .bg-inverse-danger {background-color: rgba(242, 17, 54,0.12) !important;color: #e63c3c !important;}
 .bg-inverse-purple {background: rgba(108, 97, 246, .2) !important;color: #6c61f6 !important;} 
 .bg-inverse-white {background: #fff !important;color: #484f68 !important;}
 .bg-inverse-blue {background-color: rgb(117, 197, 255, .2) !important;color: #3a90cf !important;}
 .bg-inverse-info {background-color: rgba(2, 182, 179, 0.12) !important;color: #1db9aa !important;}
 .bg-inverse-gray {background: rgb(181 181 181 / 20%) !important;color: #ababab !important;}
 .bg-inverse-int {background: rgb(54 112 188 / 20%) !important; color: #406095 !important;}
 .applicant-status-tab{padding: 0px 0px; margin-top: 10px;}
 .applicant-status-tab ul li{margin: 0 !important;}
 .applicant-status-tab ul li a{ font-size: 13px;font-weight: 600; background-color: #eee;padding: 10px 20px 10px 20px;position: relative;  margin-right: 25px;border: none !important;}
 .applicant-status-tab ul li a:before,  .applicant-status-tab ul li a:after {display: block;content: " ";position: absolute;top: 0;height: 100%;    width: 34px;background-color: #FFF;transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;border-top-left-radius: 10px;border-top-right-radius: 10px;}
 .applicant-status-tab ul li a {    border-top: 1px solid #f5f5f5 !important;}
 .applicant-status-tab ul li a:after {left: -24px;transform: skew(-30deg, 0deg);box-shadow: rgb(0 0 0 / 10%) -3px 2px 5px, inset rgb(255 255 255 / 9%) 1px 0;}
 .applicant-status-tab ul {margin: 0px;padding: 0px;overflow: hidden;padding-left: 0px;list-style-type: none;border-bottom: 0;    justify-content: center;}
 .applicant-status-tab ul li a.active, .applicant-status-tab ul li a.active:before, .applicant-status-tab ul li a.active:after {background-color: #484fbf;color: #fff;}
 .applicant-status-tab ul li:hover a:before, .applicant-status-tab ul li:hover a:after{background-color: #484fbf;}
 .applicant-status-tab ul li a:before {right: -24px;transform: skew(30deg, 0deg);box-shadow: rgb(0 0 0 / 10%) 3px 2px 5px, inset rgb(255 255 255 / 9%) -1px 0;}
 .applicant-status-tab .nav-tabs .nav-link.active {background-color: #484fbf;border-color: transparent;color: #fff;z-index: 99;}
 .applicant-status-tab  .nav-tabs .nav-link {border-radius: 0;color: #000;background-color: #fff;border-color: #dee2e6 #dee2e6 #fff;}
 .applicant-status-tab .nav-tabs .nav-link:focus,  .applicant-status-tab .nav-tabs .nav-link:hover { background-color: #484fbf;
  color: #fff;}
  .rti--container {
    padding: 16.5px 14px !important;
}
textarea {
  font-size: 14px !important;
}
.MuiTextField-root {
  width: 100%;
  font-size: 13px;
}
/*header css start*/
header { padding: 7px 15px;width: 100%;   position: sticky; top: 0; z-index: 999;}
header.fixed { position: fixed;background: #fff;background-color: #fff;top: 0;  box-shadow: 0 5px 20px rgba(0,41,112,.1);   z-index: 999;}
.inner-header { display: flex; align-items: center; justify-content: space-between;}
.bars{display: none;}
.logo {width: 130px; transition: 0.5s;}
.logo img {width: 100%;}  
.sidebarhead{display: none;}
.menu-bar  li{padding: 0px 15px;} 
.menu-bar li a {color: #000;font-weight: 500;font-size: 16px; border-bottom: 1px solid transparent;}
.menu-bar li a:hover,.menu-bar li a.active{color: #00baf2;}
.menu.navbar-nav .nav-link.active, .menu.navbar-nav .nav-link:hover{ border-color: #409de2;}
.col-buttons {position: relative;z-index: 999;    display: flex;gap: 25px; align-items: center;}
.col-buttons a {color: #000;font-weight: 500;font-size: 16px;}
.navbar-toggler{display: none;}
/*header css start*/



/*honme slider css start*/ 
.bookdemobtn {margin-top: 35px;}
.banner-content .bookdemobtn {display: flex;gap: 10px; justify-content: center;}
.banner-content .bookdemobtn .btn {max-width: 215px;flex: 1;}
.home-wrapper-area {position: relative;border-bottom: 1px solid #eee;    overflow: hidden;}
.swiper-flow {position: relative;overflow: hidden;    z-index: 9;}
.swiper {width: 100%;height: 100%;} 
.swiper-slide {text-align: center;font-size: 16px;background: #fff; display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; -webkit-justify-content: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center;align-items: center;}
.swiper-slide img {display: block;width: 100%;height: 100%;object-fit: cover;}
.home-wrapper-area .swiper-flow  .swiper-button-next {right: 15px;}
.home-wrapper-area .swiper-flow  .swiper-button-prev {left: 15px;background-color: #e0eefc;}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {right: 0;}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {left: 0px;}
.swiper-button-next:after, .swiper-button-prev:after {color: green;font-size: 16px;}
.swiper-button-prev, .swiper-button-next {background: #eee;z-index: 10;width: 45px;height: 45px;-webkit-box-pack: center;justify-content: center;-webkit-box-align: center;align-items: center;position: absolute;top: 45%;border-radius: 50%;border: 1px solid #eee;}
.swiper-pagination-bullet { width: 15px;height: 15px;background: #fff;}
.swiper-button-prev,   .swiper-button-next{background: #fff;}
.swiper-pagination-bullet{background: #409bdf;}
/*.banner .swiper-button-next , .banner .swiper-button-prev{display: none;}*/
.bg1{/* background: linear-gradient(90deg,#caeaff,rgba(236,242,246,0) 70.31%);   *//* background: #353a8d; */background-color: #fff;  background-image: radial-gradient(at 36% 63%,#e6f7ff 0,transparent 50%),radial-gradient(at 15% 77%,#e6f7ff 0,transparent 50%),radial-gradient(at 12% 25%,#ffe8db 0,transparent 50%),radial-gradient(at 43% 12%,#f5feff 0,transparent 50%),radial-gradient(at 64% 64%,#ffe9e0 0,transparent 50%),radial-gradient(at 100% 0,#e6f7ff 0,transparent 50%); background-repeat: no-repeat;background-size: cover; position: relative;}
.bgheight{padding:100px 0px;min-height: 500px; background: transparent;   }
.text-blue{color: #00baf2;}
.banner-content{text-align: center;  } 
.banner-content h1 {  margin-bottom: 20px; line-height: 61px; color: #000; font-size:50px; font-weight: 800; }
.banner-content p{font-size: 18px; font-weight: 600; color: #000;}
.banner-image {min-width: 400px;transition: all .5s;text-align: right;position: relative;z-index: 999;}
.banner-image img {height: auto;width: 100%;} 
/*honme slider css end*/

/*honme page css start*/
 
.associated-with {border-bottom: 1px solid #eee;background: #f5f7fa;}
.associatedwith {display: flex;gap: 15px;margin-top: 0px;align-items: center; justify-content: center;}
.associatedimage {padding: 0px 15px;height: 100%;display: flex;align-items: center;}
.associatedimage:last-child{border-right: none;}
.associatedwith  .associatedimage img{  width: 180px; margin-right: 25px;}
.section-title {text-align: center; margin-bottom: 60px;}
.section-title h2{font-weight: bold; text-transform: capitalize;  color: #0f172b; margin-bottom: 15px;}
.section-title p{font-size: 16px;font-weight: 500;}
.banner-pattern {    margin-bottom: 20px;}
.banner-pattern .pattern_inner {position: relative;width: 115px;display: flex;margin: 0 auto;margin-bottom: -1px;overflow: hidden;}
.banner-pattern span:first-child {    width: 100px;background: #000;}
.banner-pattern span {height: 4px;border-radius: 35px;display: inline-block;}
.line {position: absolute;height: 100%;width: 15px;background: transparent;animation: 4s ease-in-out 0s infinite normal none running lineRight;border-radius: 35px;}
.line2 {animation-delay: 2s;}
.line3 {animation-delay: 4s;    background: #409de2;}
@keyframes lineRight { 0% { left: 0px; }100% { left: 100%; }} 
.section-pedding{padding: 70px 0px;overflow: hidden;}
.card {border: 1.5px solid #eaeef2;border-radius: 8px;height: 100%;background-color: #fff;box-shadow: 0 2px 19px 0 #ededed;border-radius: 10px;border-color: rgba(222, 226, 230, .5);overflow: hidden;}
.ctacard {height: 100%;}
.cta-icon{margin: 20px 0px;}
.ctalottie{width: 100%; height: 100%;} 
.lottieanimation{box-sizing: border-box; display: block;overflow: hidden;background: none;opacity: 1;border: 0;margin: 0;padding: 0;}
.icon-circal {width: 200px;height: 200px;background-color: #fff;border: 2px solid #eee;border-radius: 50%;display: flex;align-items: center;
justify-content: center;padding: 20px; margin: 0 auto;}
.icon-circal img { width: 100%; height: 100%; object-fit: cover;}
.cta-title {text-align: center;}
 .cta-title h3{ color: #000;font-weight: bold !important;}
.cta-read {margin: 25px 0px 15px 0px;}
.cta-read a {display: flex;align-items: center;gap: 10px; font-weight: 500; }
.cta-read a i{font-size: 22px; display: flex;}
.Transforming{    --tw-bg-opacity: 1;background-color: #f5f7fa;     position: relative;  }
.whyit img { position: absolute; left: 0; top:0; width: 30%;max-width: unset;max-height: unset; }
.middlebanner{padding: 100px 0px;}
.webreverce{flex-direction: row-reverse;}
.Dashboard{    background: linear-gradient(90deg,#353a8d,#081d4e)!important;}
.Dashboard .section-title h2, .Dashboard .section-title p{color: #fff;}
.hero-bg-image-holder {position: relative;display: flex;justify-content: center;align-items: center;}
.hero-bg-image-holder img {width: 100%;z-index: 999;}
.join {display: flex;align-items: center;gap: 15px;justify-content: center;margin-top: 35px;}
 .join .form-control {flex-grow: 1;     border: 1px solid #e4e4e4;    height: 50px;}
 .join .btn { flex-shrink: 0;   }
.feature-tab-info.card {height: 100%;box-shadow: 0 5px 20px 0 rgb(233 233 233 / 50%);border-color: #eee;}
.mt-15{margin-bottom: 10px;}
.feature-tab-head {border-bottom: 1px solid #eee;--tw-bg-opacity: 1;    background-color: #f5f7fa;height: 250px;padding: 15px;}
.feature-tab-head img { width: 100%;height: 100%;object-fit: contain;}
.feature-tab-body {padding: 20px 15px 15px 15px;text-align: center;}
.feature-tab-info h3 {font-size: 16px;margin-bottom: 15px;line-height: 25px;font-weight: 600;}
canvas { width: 100%;position: absolute;z-index: 9;height: 100%;top: 0;}
.matrixeffect{z-index: 1;opacity: .3;}
.try-it {text-align: center; text-transform: uppercase;font-weight: 600;}
.try-it h5 {font-weight: 600;}
.detail-cv {margin-top: 25px;display: flex;flex-wrap: wrap;row-gap: 15px;column-gap: 15px;}
.webjd-title h4 {font-weight: 600;}
.webjd{margin-bottom: 30px;}
.select-aibtn {margin-top: 30px;}
.user-profile-pick .MuiAvatar-circular {background-color: #5e64cb;color: #fff;font-size: 16px;}
.candidate-summary-top {display: flex;gap: 15px; align-items: center;padding: 15px;background: #f9f9f9;}
.candiatend h5 {font-size: 16px;}
.candiatend span{font-size: 12px;}
.candidate-summary-right{  padding: 10px 15px;    background: #fff;}
.score-name h5 {font-size: 12px; font-weight: 400; margin-bottom: 0; line-height: 18px; height: 45px;display: flex;
  align-items: center;}
.score-percentage .MuiLinearProgress-root {    width: 75px;flex: 1;border-radius: 10px;height: 7px;}
.score-percentage {display: flex;align-items: center;gap: 15px;flex-basis: 45%;flex-shrink: 0;}
.candidatescore-row {display: flex;align-items: center;  flex-direction: column;justify-content: center;text-align: center;flex: 1;border-right: 1px solid #e2e2e2} 
.candidatescore-row:last-child{border: none;}
.candidate-score-card {display: flex; }
.alart-ai {margin-bottom: 15px;}
.alart-ai .alert {
  margin-bottom: 0;
  
  padding: 7px 15px;
}
.score-name {display: flex;align-items: center;gap: 15px;flex-grow: 1;}
.score-percentage p{font-weight: bold;font-size: 16px;color: #000;}
.candidatescore-head { display: none; align-items: center;justify-content: center;gap: 6px; padding:25px  15px;flex-direction: column;} 
.cv-analysis-demo {position: relative; scroll-margin:150px;}
.demo-editor {height: auto; overflow: inherit;min-height: 200px;border: 1px solid #F1F1F1 !important;padding: 5px 15px !important;
border-radius: 2px !important;background-color: #fcfcfc;}
.jobdescription-detail {overflow: auto; max-height: calc(100vh - 40px); padding: 30px;}
.jobdescription-detail .form-group label{font-weight: 600;}
.analysis-upload {position: relative;}
.analysis-upload  label { width: 100%;}
.analysis-upload input {position: absolute;right: 0;-webkit-appearance: none;opacity: 0;height: 100%;z-index: 999;left: 0;right: 0; margin: 0 auto;top: 0;}
.howwork{position: relative;}
.howwork:before {
  content: '';
  position: absolute;
  width: 1px;
  height: 1px;
  display: block;
  background: #353a8d;
  border-radius: 50%;
  box-shadow: 0 0 1000px 350px #353a8d;
  z-index: -1;
  right: 0;
  top: 100%;
  transform: translate(-80%, -80%);
  opacity: .2;
}
.howwork:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 1px;
  display: block;
  background: #00baf2;
  border-radius: 50%;
  box-shadow: 0 0 1000px 350px #00baf2;
  z-index: -1;
  left: 0;
  top: 100%;
  transform: translate(80%, -80%);
  opacity: .2;
}
.reverce {
  flex-direction: row-reverse;
}
.reverce .webjd {
  
  padding-left: 15px;
}
.taginput .rti--container{    padding: 16.5px 14px !important;}

.aicv-height {
  
  min-height: 400px;
 
}

.summary-group-wrapper {
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 0px, rgba(0, 0, 0, 0.14) 0px 1px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 2px 0px;
  border: 1px solid transparent;
  overflow: auto;
 
  max-height: calc(100vh - 406px);
  
}

.educationai{ padding: 10px 15px;     box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);}
.scoreeducation {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 9px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f1f0ff;
  color: #000;
}
.score-lable {
  font-weight: 600;
}
.scoreeducation span b {
  font-size: 11px;
  margin-top: 2px;
  display: block;
}
.summary-group-wrapper .candidatetest-row .MuiAccordionSummary-content {
  margin: 10px 0px;     
}
.summary-group-wrapper .cv-collapes .MuiAccordionSummary-root.Mui-expanded {
  background-color: #e5ecff;
}
.summary-group-wrapper .cv-collapes .MuiAccordionSummary-root.Mui-expanded .candidate-work-head .srnum {
  background-color: #fff;
}
.candidate-work-head {
  display: flex;
  align-items: center;
}
.candidate-work-head .srnum {
  width: 20px;
  height: 20px;
  font-size: 12px; 
  display: flex;
  flex-shrink: 0;
  background: #e5ecff;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #9da3b6;
  margin-right: 15px;
}
.company-name span {
  font-size: 13px;
  font-weight: 900;

}
.workdate {
  display: flex;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  row-gap: 5px;
  margin-top: 6px;
}
.date-cta {
  display: flex;
  font-size: 12px;
  gap: 5px;
  align-items: center;
  border-right: 1px solid #9c9c9c;
  padding: 0px 10px;
}
.date-cta:first-child {
  padding-left: 0;
}
.date-cta i {
  font-size: 16px;
}
.company {
  margin-bottom: 10px;
  padding-left: 40px;
}
.company span {
  margin-bottom: 3px;
  display: block;
  font-size: 13px;
}
.middle-gap {
  padding: 10px 0px;
}
.middle-row {
  display: flex;
  gap: 10px;
  color: #000;
  font-size: 12px;
}
.middle-lable svg {
  color: #d3302f;
  font-size: 16px;
}
 
.flex-head {
  /* background: #fbfbfb; */
  border: 1px solid #eee;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.flex-head.active {
  background: #fbfbfb;
}
.flex-head .card-icon {
  width: 35px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  display: inline-flex;
  background: #eee;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.flex-head.active .card-icon{background-color: #fff;}
.flex-head .card-icon svg {
  font-size: 20px;
  color: #9da3b6;
}
.analysis-box {
  margin: 10px 10px;
}
.flex-head h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.skill-indicator {
  padding: 15px 15px 0px 15px;
}
.skill-indicator ul {
  display: flex;
  gap: 15px;
  justify-content: center;
}
.skill-indicator ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}
.statusdot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00d78e;
}
.diffrent-skill {
  display: flex;
  gap: 15px;
}
.form-skill {
  width: 50%;
  border-right: 1px solid #eee;
}
.form-skill:last-child{  border-right:none;}
.Collaboratorname {
 
  width: 100%;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: flex-start;
}
.Collaboratorname svg {
  font-size: 14px;
}
.ai-image {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 15px;
}
.ai-image img {
  width: 200px;
}
.ai-image{font-weight: 600;}
.cvanalysis {
  min-width: 469px;
  transition: all .5s;
  text-align: right;
  position: relative;
  z-index: 999;
  border: 1px solid #eee;
   
}
.cvanalysis img {
  max-height: 30vh;
  aspect-ratio: auto 690/460;
  margin: 0 auto;
  height: auto;
  width: auto;
}
.redirectsignin {
  text-align: center;
  margin: 15px 0px;
}
.redirectsignin .btn{font-size: 12px;}

.generated-jd {
  display: flex;
  gap: 15px;
}
.aijd, .jd-Suggestion-wrapper {
  flex: 1 1;
}
.jd-Suggestion-wrapper {
 
  position: relative;
}
.fond-icon-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bigimage img {
  width: 400px;
}
.fond-icon-content span {
  font-weight: 500;
  font-size: 15px;
}

.page-loader {
  position: absolute;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 99;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-loader {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  font-size: 12px;
  font-size: 14px;
}
.inner-loader span {
  display: block;
  margin-top: 10px;
  font-size: 16px;
}

.jd-Suggestion-list {
  border-radius: 10px;
  border: 1px solid #eee;
}
.suggetion-box {
  padding: 15px 15px 0px 0px;
}
.suggetion-skills {
  padding: 15px 0px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.suggetion-title {
  text-transform: uppercase;
}

.aijd .demo-editor {
        
  overflow: auto;
  max-height: calc(100vh - 270px);
}
.aijd  .demo-wrapper{margin-bottom: 0 !important;}
.jd-Suggestion-list {
border-radius: 10px;
  border: 1px solid #eee;
}
.jd-Suggestion-headlist {
  
  border-bottom: 1px solid #f4e4fc;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
 
}

.jd-Suggestion-headlist h5{color: #212639;
  margin-bottom: 0;
  font-weight: 600;}
.question_header_content label {
  font-weight: bold;
  color: #000;
  font-size: 13px;
}
.suggested-answer-content {
  display: flex;
  gap: 15px;
  flex-grow: 1;
}
.jd-Suggestion-headlist svg{color: #ff960f;}

.suggested-answer-content .question_essence   {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  color: #000;
}
.jd-Suggestion-answer {
  font-size: 12px;
}
.jd-Suggestion-name {
  flex-grow: 1;
}
.suggested-row{display: flex; gap: 15px; border-radius: 10px;    padding: 15px; margin-bottom: 7px;}  

.jd-Suggestion-body {
 padding: 10px;
  overflow: auto;
  max-height: calc(100vh - 380px);
  position: relative;
  min-height: 400px;
   
}
.response-ans{cursor: pointer;    margin-top: 5px;    display: flex;
  gap: 15px;}
  .response-ans svg{font-size: 16px;}
.suggested-inner-list {background: #fbfbfb;  border-radius: 10px;  padding: 15px;
  margin-bottom: 10px; border: 1px solid #eee;}
.suggested-inner-list  label{margin-bottom: 15px; font-size: 14px; font-weight: bold; color: #000;    display: flex;
  gap: 10px; align-items: center;}
.invitecount{font-size: 12px;    color: #6c757d;}
.refreshjd{margin-top: 15px; margin-bottom: 15px; padding: 0px 15px;}
.refreshjd .btn{padding: 3px 10px ;}
.activesuggested .suggested-row{background-color:rgba(15, 183, 107,0.12) !important;color: #26af48 !important;}
.response-ans span{color: #000;}

.jd-Suggestion-name input{background-color: #fff !important;}

.submitsuggection{display: flex; align-items: center; gap:10px}
.saveico{width: 30px; height: 30px; flex-shrink: 0; border-radius: 50%; background-color:#fff; display: flex; align-items: center; justify-content: center; border: 1px solid #eee;}
.saveico svg{font-size: 18px;}
.suuggection-save{flex-shrink: 0; display: flex; gap: 10px;} 

.jd-list {
  flex-grow: 1;
}
.jd-list .dropdown-option{flex-basis: 100%;}
.jd-listing ul li {
  color: #000;
  padding: 5px 0px;
  list-style: auto;
}
.default-list {
  padding-right: 20px;
  flex-grow: 1;
}
.jd-edit{flex-shrink: 0; color: #000;}

.create-test-box {
  display: flex;
  align-items: center;
  gap: 25px;
}
.test-vactor {
  width: 500px;
}
.test-vactor img {
  width: 100%;
}
.test-creationfield {
  flex-grow: 1;
}
.create-test-wrapper{    margin-top: 10px;}
.testid {
  padding: 2px 10px;
  border-radius: 3px;
}
.job-icon .MuiAvatar-circular{font-size: 13px;}
.bg-primary2 {
  background-color: #e8e7fd;
  color: #7367f0;
}

.answertype{flex-grow: 1;}
.drop-option-row {
  display: flex;
  margin-bottom: 10px;
  gap: 15px;
}
.jd-list .dropdown-option {
  flex-basis: 100%;
}
.dropdown-option {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-basis: 50%;
}
.icon a i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  transition: 0.3s;
}
.dropdown-field {
  flex-grow: 1;
}
.add-btn {
  margin-top: 15px;
}
/*honme page css end*/

/*plan page css start*/
.fs26{font-size: 26px !important; }
.blur{filter: blur(3px);
  -webkit-filter: blur(5px);}
.minibanner {
  text-align: center;
  padding: 50px 0px 50px 0px;  
  position: relative;
  display: flex;
  align-items: center;
}
.comon-inner-banner h2 {
 
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 67px;
  font-size: 50px;
}
.comon-inner-banner p {
  font-size: 24px;
}
.plan-duration .MuiSwitch-track {
 
  background-color: #65C466 !important;}
  .plan-duration .MuiFormControlLabel-root , .plan-duration .MuiSwitch-root{margin: 0 !important;    width: 55px;}
.plan-duration {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 50px;
}
.plan-duration   .MuiSwitch-switchBase.Mui-checked {
  -webkit-transform: translateX(28px) !important;
  -moz-transform: translateX(28px) !important;
  -ms-transform: translateX(28px) !important;
  transform: translateX(28px) !important;
  color: #fff;
}

.duration.active{font-weight: 600;}
.comon-inner-banner{ }

.ldplan {
  z-index: -1;
  position: absolute;
  
 
}
.ldplan {
  width: 80px;
  height: 387px;
}
.ldplan:first-of-type {
  top: -20px;
  left: 45px;

}
.ldplan:nth-of-type(2) {
  left: 20%;
  bottom: 40px;
  opacity: 0.5;
}
.ldplan:nth-of-type(3) {
  right: 25%;
  bottom: 40px;
  opacity: 0.5;
}
.ldplan:nth-of-type(4) {
 
  display: block;
  top: -20px;
  right: 45px;

}
.ldplan:nth-of-type(5) {
  display: block;
  left: 0%;
  bottom: -100px;
}
.ldplan:nth-of-type(6) {
  right: 8%;
  bottom: 50px;
  transform: unset;
}

.lddeg, .ldcol {
  width: 100%;
  height: 100%;
}
.lddeg {
  position: relative;
  -webkit-mask-image: linear-gradient(180deg,#000,transparent);
  mask-image: linear-gradient(180deg,#000,transparent);
}
.ldcol {
  position: absolute;
  border-radius: 50px;
  background: linear-gradient(180deg,#e3e5ff,rgba(0,211,127,0));
}

.ldimg {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50px;
  mix-blend-mode: overlay;
  opacity: .3;
  background-image: url(../images/do_not_delete_noise.png);
}
.plan-tables-container {
  display: flex;
  /* align-items: center; */
  gap: 0px;
  margin-bottom: 20px;
}
 
.plan-table-item {
  padding: 15px 0px ;
  background: #f5f7fa;
  border-radius: 10px;
  flex: 1;
}
.plan-title {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 15px;
  padding: 0px 15px;
  height: 90px;
  
}
.plan-title h3 {
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
}
.plan-cost-box{margin: 25px 0px; text-align: center;  padding: 0px 15px;}
.plan-cost-box span{font-size: 11px;}
.plan-cost {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  
}
.plan-cost h5 {
  text-align: center;
  margin-bottom:0px;
  font-size: 26px;
  font-weight: 800;
}
.pricing-description {
  font-size: 15px;
}
.plan-cost small {
  font-size: 18px;
}
.plan-btn { 
  padding: 15px 15px;
}
.plan-btn .btn{margin: 0;}
.popularitem{background-color: #676ff0;    color: #fff; position: relative;  overflow: hidden;  }
.plantagline{color: #676ff0;  font-weight: bold;}
.popularitem .plantagline {
  color: #fff;
  font-weight: bold;
}
.bubble {
  position: absolute;
  top: -0px;
  left: 0;
}
.bubble img {
  width: 300px;
  opacity: 0.1;
}
.popularitem .ldplan:nth-of-type(1){
  top: -185px;
  left: 0;
  right: inherit;
  bottom: inherit;
  opacity: 0.2;
}
.popularitem .ldplan:nth-of-type(2){
  top: inherit;
  left: inherit;
  right: 0;
  bottom: -185px;
  opacity: 0.2;
}
.popularitem .plan-title h3{color: #fff;}
.bgpopular{background: #5962dd;}
.item-features {margin-top: 15px;
  padding: 15px;}

  .bggray{background: #e0e6ee;}
.item-features ul{padding: 0; margin: 0;}
.item-features ul li {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 10px;
  font-size: 15px;
}
.item-features ul li svg{font-size: 18px; color: #66ce8a;}

.Features-table table tr th ,.Features-table table tr td{text-align: center; font-size: 15px; padding: 12px 8px;   vertical-align: middle;}
.f-check{    font-size: 18px;
  color: #66ce8a;}
  .f-uncheck{color: #ff6060;}
  .Features-table table tr th:first-child, .Features-table table tr td:first-child{text-align: left; width: 46%;}
  .Features-table table tr th:nth-child(2), .Features-table table tr td:nth-child(2){ width: 18%;}
  .Features-table table tr th:nth-child(3), .Features-table table tr td:nth-child(3){ width: 18%;}
  .Features-table table tr th:nth-child(4), .Features-table table tr td:nth-child(4){ width: 18%;}
  .Features-table table tr th {
    background: #f9f9f9;
    border: 1px solid #eee;
    text-transform: uppercase;
    font-weight: 600;
}
.progressBar {
  transition: width 1s ease-in-out;
}

.addons-box {
  background: #f5f7fa;
  padding: 0px 30px 30px 30px;
}
.addons-title{padding: 30px 0px; text-align: center;}
.addons-title h2{      font-weight: bold;
  text-transform: capitalize;
  color: #0f172b;
  margin-bottom: 0px;}
 
.add-ons-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
background-color: #fff;
padding: 10px 0px;
border-radius: 50px;
 text-align: center;
 
}
.add-ons-row label {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 7px;

}

.add-ons-row label  svg {
  font-size: 18px;
  color: #66ce8a;
}
.amount-value {
  font-weight: 600;
  font-size: 16px;
  color: #409de2;
  display: flex;
  align-items: center;
  gap: 3px;
}

.amount-value small {
  font-size: 12px;
}
 .currency-symbol {
  position: relative;
  top: -3px;
   
}
.currency-symbol svg{font-size: 16px;}

.add-ons-flex {
  display: flex;
 
}
.flex1{flex: 1; padding: 30px; background-color: #fff; border: 1px solid #eee;   position: relative;   margin: 0px 15px; margin-bottom: 15px; }
.flex1:after{content: ''; width: 1px; background-color: #d8dce3; height: 100%; position: absolute; top: 0; right: -15px;  }
.flex1:last-child::after{display: none;}
.flex1:last-child{border: none;}
 .duration {
  position: relative;
}
.duration small {
  position: absolute;
  left: 65px;
  width: 100px;
  top: 3px;
}

.addon-info {
  /* background: #e9ebff; */
  /* padding: 12px 20px; */
  border-radius: 4px;
  margin-bottom: 15px;
  /* font-weight: 600; */
  text-align: center;
  line-height: 27px;
  margin-top: 17px;
}
.credittitle{margin-bottom: 15px; text-align: center;}
.credittitle h3 {
  font-size: 18px;
  font-weight: 700;
}


/*plan page css end*/
 
/*about us page css start*/
.aboutus {
  background-color: #e2d8fd;
}
.aboutus {
  background-color: #353a8d;
  position: relative;
  z-index: 9;
  padding: 50px 15px;
  overflow: hidden;
}

.aboutus .ldcol {
  
  background: linear-gradient(180deg,#8286c2,rgba(0,211,127,0));
}
.aboutus .planmiddlecontent h2 , .aboutus .planmiddlecontent p{color: #fff; }


/*about us page css end*/

.our-story{position: relative; text-align: center;}
 
.our-story  .banner-image  {
  border: 29px solid #fff;
  border-radius: 19px;
}
.weare {
  align-items: center;
  background: #fff;
  border-radius: 15px;
  box-shadow: 1px 3px 99px rgba(0,0,0,.06);
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 50px;
  position: relative;
  border: 2px solid #00baf2;
}
.weare::after{ content: ''; background-image: url(../images/logo-watermark.png);     background-position: center;
  background-size: 100%;
  height: 100%;
  width: 43%;
  position: absolute;
  background-repeat: no-repeat; opacity: 0.7;}
.weare  p{    color: #001033;
  font-size: 22px; 
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 16px;
   }
.our-story .section-title{text-align: left;}

.our-story img  {border-radius: 15px;  
}
.aboutng {
  background: #f0f0fb;
  padding: 150px 0px;
  position: relative;
  z-index: 1;
}
.aboutng:after {
  content: '';
  background-image: url(../images/logo-watermark.png);
 
  position: absolute;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 90%;
  width: 100%;
  left: 0;
  right: 0;
 
  margin: 0 auto;
  padding: 20px 0px;
  opacity: 0.4;
  z-index: -1;
  transform: translate(0px, 30px);
}
.aboutng p{font-size: 20px; line-height: 35px; font-weight: 500;}
/*footer css srart*/
footer {background-color: rgba(244,244,246,.61);position: relative;z-index: 9; }
/*{footer .footer-elment { width: 287px; height: 287px; position: absolute; left: -225px; top: -30px; border-radius: 50px; box-shadow: 0 3px 36px rgb(0 0 0 / 16%); opacity: .46; transform: rotate(45deg); background: #fff;}*/
.copyright-section {background-color: #100f1f;padding: 25px 15px;text-align: center;   font-size: 14px;}
.footer-links   a {list-style: none;display: inline-block;font-size: 14px;line-height: 1;color: #fff; }
.copyright p {color: #fff; }
.footer-links a:not(:last-child) { padding-right: 15px; margin-right: 15px; border-right: 1px solid;}
.footer-bottom {display: flex;align-items: center;justify-content: center;gap: 65px;}
.flag { display: flex; gap: 10px; align-items: center; color: #fff; }
.flag .icon-contact img {width: 25px; }
.flag .contact-cta{padding: 0px;} 
.social-media a {color: #fff; margin: 0px 5px;}
.social-media a i {font-size: 30px;}
.social-media a:hover{color: #3a8cf0;}
.contact-detail {display: flex;gap: 13px;align-items: center;}
.contact-end {display: flex; gap: 20px;}
.contact-row {display: flex;gap: 5px;align-items: center;} 
.contact-icon {display: flex;align-items: center;justify-content: center;}
.contact-icon svg{  font-size: 30px;    color: #00baf2;}
.contact-information h6{margin-bottom: 0; margin-top: 5px;}
.contact-information span {font-weight: 500;}
.contact-row{margin: 15px 0px;}
.contact-information {display: flex; gap: 15px; justify-content: center;}
.contact-information h6 a {color: #fff;}
.contact-information h5{color: #fff; margin-bottom: 0;}
.copyright  a { text-decoration: none;color: #00baf2;}
/*footer css end*/      





 


    /*login page css start*/
.auth-bg {position: relative;}
.auth-bg { height: 100vh;    overflow: hidden;}
.bg-overlay {position: absolute;height: 100%;width: 100%;right: 0;bottom: 0;left: 0;top: 0;opacity: .7;background-color: rgba( 22,115,203,0.9);}
.auth-bg .bg-overlay {opacity: .9;}
.bg-bubbles {position: absolute;top: 0;left: 0;width: 100%;height: 100%;overflow: hidden;}
.bg-bubbles li {position: absolute;list-style: none;display: block;width: 40px;height: 40px;border-radius: 30px;background-color: rgba(255, 255, 255, .1);bottom: -50px;-webkit-animation: square 20s infinite;animation: square 20s infinite;-webkit-transition-timing-function: linear;transition-timing-function: linear;}
.bg-bubbles li:nth-child(1) {left: 10%;}
.bg-bubbles li:nth-child(2) {left: 20%;width: 120px;height: 120px;-webkit-animation-delay: 2s;animation-delay: 2s;-webkit-animation-duration: 17s;animation-duration: 17s;}
.bg-bubbles li:nth-child(3) {left: 25%;-webkit-animation-delay: 4s;animation-delay: 4s;}
.bg-bubbles li:nth-child(4) {left: 40%;width: 80px;height: 80px;-webkit-animation-duration: 22s;animation-duration: 22s;}
.bg-bubbles li:nth-child(5) {left: 70%;width: 90px;height: 90px;}
.bg-bubbles li:nth-child(6) {left: 70%;width: 120px; height: 120px;-webkit-animation-delay: 3s;animation-delay: 3s;}
.bg-bubbles li:nth-child(7) {left: 32%;width: 150px;height: 150px;-webkit-animation-delay: 7s;animation-delay: 7s;}
.bg-bubbles li:nth-child(8) {left: 55%; width: 80px;height: 80px;-webkit-animation-delay: 15s;animation-delay: 15s;-webkit-animation-duration: 40s;animation-duration: 40s;}
.bg-bubbles li:nth-child(9) {left: 25%;width: 50px; height: 50px; -webkit-animation-delay: 2s; animation-delay: 2s; -webkit-animation-duration: 40s; animation-duration: 40s;}
.bg-bubbles li:nth-child(10) {left: 90%; width: 140px; height: 140px; -webkit-animation-delay: 11s; animation-delay: 11s;}
.corporate-image {position: relative;min-width: 469px;transition: all .5s;text-align: right;position: relative;z-index: 999;}
.corporate-image img {max-height: 100vh; aspect-ratio: auto 690/460;margin: 0 auto;height: auto;width: auto;object-fit: contain;width: 100%;}
@-webkit-keyframes square {0% {	-webkit-transform: translateY(0);transform: translateY(0)}
100% {-webkit-transform: translateY(-1000px) rotate(600deg);transform: translateY(-1000px) rotate(600deg)}}
@keyframes square {0% {-webkit-transform: translateY(0);transform: translateY(0)}100% {-webkit-transform: translateY(-1000px) rotate(600deg);transform: translateY(-1000px) rotate(600deg)}}
.auth-full-page-content {overflow: auto;max-height: calc(100vh - 20px);padding: 10px;position: relative;}
.welcometext h5 {font-weight: 600;}
.welcometext p{font-size: 14px;}
.logincorporate label {font-size: 14px; font-weight: bold;}
.logincorporate .form-control {height: 46px;font-size: 14px;border-color: #ced4da;}
.whatsapbtn {text-align: center;}
.whatsapbtn img {width: 300px;margin: 0 auto;}
.notmember p {font-size: 14px; color: #74788d;}
.forgotpass a { font-size: 14px;}
.login-box p, .note {font-size: 14px;color: #526484;}
/*login page css end*/

 /*book demo page css start*/
 .form-option   label{align-self: flex-start;}
.form-option {display: flex;justify-content: center;}
.trial-content .free-trial {position: absolute;font-size: 12px; font-weight: 500;left: 0;right: 0;}
.bookdemoauth-full-page-content  .section-title {margin-bottom: 55px;}
.trial-content span{display: block;}
.book-demo { background: linear-gradient(90deg,#353a8d,#081d4e)!important;}
.book-demo .bookdemoauth-bg    {    height: auto;padding: 50px 25px 50px 25px;}
.book-demo .bookdemoauth-full-page-content{   padding: 50px 25px 50px 25px; height: 100%;background-color: #f7f4f4; }
.bookdemo-image {padding: 15px 0px;display: flex;align-items: center;justify-content: center;}
.bookdemo-image img{width: 100%;}
.bookdemo-title {text-align: center;}
.bookdemo-title h3{color: #fff;}
.point-title p {color: #fff;margin-bottom: 0;}
 .pointsec-row {display: flex;align-items: center;gap: 15px;margin: 15px 0px;}
.point-icon {width: 35px;height: 35px;/* background: #fff; */border-radius: 50%;/* padding: 4px; */flex-shrink: 0;display: flex;align-items: center;justify-content: center;}
.point-icon img {width: 100%;height: 100%;/* object-fit: cover; */}
.bookdemo-point { padding: 25px 15px;font-size: 16px;display: flex;gap: 25px;}
.globlepointsec {display: flex;gap: 10px; justify-content: space-evenly;}
.globlepointsec .pointsec-row {margin: 0;}
.globlepointsec-wrapper {background: #fff;padding: 15px;border-radius: 15px;}
.globlepointsec .point-title p{color: #353a8d; font-weight: bold;}
.globlepointsec  .point-icon{    background: #409bdf;color: #fff;}
.globlepointsec-wrapper h6 {text-align: center;margin-bottom: 15px;font-weight: bold;}
.point-title img {height: 59px;margin-top: 4px;background: #fff;padding: 9px 10px;border-radius: 10px;}
.worldwidephone {border: 1px solid #c4c4c4 !important;}
.PhoneInputCountry{padding: 0px 8px;}
.worldwidephone .PhoneInputInput{    flex: 1 1; min-width: 0;font: inherit;letter-spacing: inherit;color: currentColor;padding: 4px 0 5px;border: 0;box-sizing: content-box;background: none;height: 1.4375em;margin: 0;-webkit-tap-highlight-color: transparent;display: block;min-width: 0;width: 100%;-webkit-animation-name: mui-auto-fill-cancel;animation-name: mui-auto-fill-cancel;-webkit-animation-duration: 10ms;animation-duration: 10ms; padding: 16.5px 4px; border-left: 1px solid #c4c4c4;}
.registernowbtn{display: none;}
.bookdemoauth-full-page-content .auth-content   .form-group .form-label{font-weight: 500;}
.registernowbtn .btn{ background-color: #3dba74; color: #fff;} 
.registernowbtn  .btn:hover,.registernowbtn  .btn:focus{  background-color: #3dba74;color: #fff;}
/*book demo page css end*/


/*success popup  css start*/
 .modal-common  .modal-header {border-bottom: none;text-align: center;display: initial;padding-bottom: 30px;}
.modal-common .icon-box {display:flex;color: #fff;margin: 15px auto 10px auto;left: 0;right: 0;top: -55px;width: 95px;height: 95px;border-radius: 50%;z-index: 9;background: transparent;padding: 15px;text-align: center;border: 1px solid #82ce34;box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);}
.modal-common .icon-box i {font-size: 58px;position: relative;top: 3px; }
.icon-box i { width: 55px; height: 55px; line-height: 55px; text-align: center; font-size: 22px;} 
.modal-confirm .icon-box i { color: #82ce34;}
.modal-confirm   h4 { color: #82ce34; font-size: 20px; font-weight: 800; margin-bottom: 0px;} 
.modal-common .model-content{margin-top: 30px;}
.modal-common  h3 {color: #000;font-weight: bold;}
.modal-common .btn-success {background-color: #82ce34; border-color: #82ce34;  }
.modal-btn{display: flex; gap: 10px; justify-content: center; margin-top: 30px;}
.modal-btn .btn{flex: 1;}
.modal-header{background: #f1f5f8; position: relative;  padding: 15px;border-bottom: 1px solid #eee;justify-content: center;}
.modal-header h4{font-weight: bold;}
.modal-body{padding: 25px;}
.close {background-color: #fff; border-color: #fff; color: #000; border: 0; border-radius: 50%; color: #000;font-size: 16px;height: 25px;width: 25px;line-height: 20px;margin: 0;opacity: 1;padding: 0;position: absolute;right: 15px;top: 15px;z-index: 99;}
.deactive .icon-box {border: 1px solid #bb2d3b;}
.deactive .icon-box i {color: #bb2d3b;}
/*popup  css start*/



.errorpage {
  overflow: hidden;
  max-height: 100vh;
}

.errorpage {
  min-height: 100vh;
  padding: 20px 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
 
  overflow: hidden;
  max-height: 100vh;
  z-index: 111;
  position: absolute;
  top: 0;
  left: 0;
}

.errorpage__content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.error__message {
  text-align: center;
  color: #fff;
}
.message__title {
   
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-size: 5.6rem;
 
  max-width: 960px;
  margin: 0 auto;
}
.message__text {
   display: block;
  
  font-size: 18px;
  padding: 0 60px;
  max-width: 920px;
  margin: auto;
  margin-top: 25px;
}

.errorlogo{width: 200px; margin: 0 auto; margin-bottom:150px;}
.errorlogo img{width: 100%;}

.errorpagebtn {
  margin-top: 30px;
}

.errorpagebtn .btn {
  padding: 15px 25px;
  font-size: 18px;
  text-transform:none;
  margin-top: 30px;
}
